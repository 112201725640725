/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import { defineMessages, FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Post from '../../components/Post/Post';
import Feedback from '../../components/Feedback/Feedback';
import * as styles from './ProductPage.module.scss';
import Button, { ButtonType } from '../../components/Button/Button';
import tiktokLogo from './img/tiktokLogo.png';
import { getServiceName } from '../../helpers/helpers';
import Seo from '../../components/Seo/Seo';

const messages = defineMessages({
    metaTitle: {
        id: 'productPage.metaTitle',
        defaultMessage: '{product} {service} - Cheap social media services | Cheap-Social.com',
    },
    metaDesc: {
        id: 'productPage.metaDescription',
        defaultMessage:
			'{product} {service}, buy {product} {service}, gain {product} {service}, get more {product} {service}, buy real {product} {service}, buy {product} {service} cheap, real {product} {service}, buy {product} {service} cheap, how to buy {product} {service}, increase {product} {service}',
    },
    title: {
        id: 'productPage.about',
        defaultMessage: 'About',
    },
    TIKTOK: {
        id: 'productPage.aboutPost.TIKTOK',
        defaultMessage:
			'TIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TIKTOKlikes: {
        id: 'productPage.aboutPost.TIKTOKlikes',
        defaultMessage:
			'TIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TIKTOKviews: {
        id: 'productPage.aboutPost.TIKTOKviews',
        defaultMessage:
			'TIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TIKTOKfollowers: {
        id: 'productPage.aboutPost.TIKTOKfollowers',
        defaultMessage:
			'TIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    INSTAGRAM: {
        id: 'productPage.aboutPost.INSTAGRAM',
        defaultMessage:
			'INSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    INSTAGRAMlikes: {
        id: 'productPage.aboutPost.INSTAGRAMlikes',
        defaultMessage:
			'INSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    INSTAGRAMviews: {
        id: 'productPage.aboutPost.INSTAGRAMviews',
        defaultMessage:
			'INSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    INSTAGRAMfollowers: {
        id: 'productPage.aboutPost.INSTAGRAMfollowers',
        defaultMessage:
			'INSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    PINTEREST: {
        id: 'productPage.aboutPost.PINTEREST',
        defaultMessage:
			'PINTERESTLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    PINTERESTfollowers: {
        id: 'productPage.aboutPost.PINTERESTfollowers',
        defaultMessage:
			'PINTERESTLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    PINTERESTsaves: {
        id: 'productPage.aboutPost.PINTERESTsaves',
        defaultMessage:
			'PINTERESTLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    SPOTIFY: {
        id: 'productPage.aboutPost.SPOTIFY',
        defaultMessage:
			'SPOTIFYLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TUMBLR: {
        id: 'productPage.aboutPost.TUMBLR',
        defaultMessage:
			'TUMBLRLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TWITCH: {
        id: 'productPage.aboutPost.TWITCH',
        defaultMessage:
			'TWITCHLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    LINKEDIN: {
        id: 'productPage.aboutPost.LINKEDIN',
        defaultMessage:
			'LINKEDINLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    YOUTUBE: {
        id: 'productPage.aboutPost.YOUTUBE',
        defaultMessage:
			'YOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    YOUTUBEsubscribers: {
        id: 'productPage.aboutPost.YOUTUBEsubscribers',
        defaultMessage:
			'YOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    YOUTUBElikes: {
        id: 'productPage.aboutPost.YOUTUBElikes',
        defaultMessage:
			'YOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    YOUTUBEviews: {
        id: 'productPage.aboutPost.YOUTUBEviews',
        defaultMessage:
			'YOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    FACEBOOK: {
        id: 'productPage.aboutPost.FACEBOOK',
        defaultMessage:
			'FACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    'FACEBOOKpage-likes': {
        id: 'productPage.aboutPost.FACEBOOKpage-likes',
        defaultMessage:
			'FACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    'FACEBOOKpost-likes': {
        id: 'productPage.aboutPost.FACEBOOKpost-likes',
        defaultMessage:
			'FACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    'FACEBOOKprofile-followers': {
        id: 'productPage.aboutPost.FACEBOOKprofile-followers',
        defaultMessage:
			'FACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TWITTER: {
        id: 'productPage.aboutPost.TWITTER',
        defaultMessage:
			'TWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TWITTERlikes: {
        id: 'productPage.aboutPost.TWITTERlikes',
        defaultMessage:
			'TWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TWITTERretweets: {
        id: 'productPage.aboutPost.TWITTERretweets',
        defaultMessage:
			'TWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    TWITTERfollowers: {
        id: 'productPage.aboutPost.TWITTERfollowers',
        defaultMessage:
			'TWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    titleMore: {
        id: 'productPage.moreTitle',
        defaultMessage: 'More information',
    },
    morePostTIKTOK: {
        id: 'productPage.morePostTIKTOK',
        defaultMessage:
			'morePostTIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTIKTOKfollowers: {
        id: 'productPage.morePostTIKTOKfollowers',
        defaultMessage:
			'morePostTIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTIKTOKlikes: {
        id: 'productPage.morePostTIKTOKlikes',
        defaultMessage:
			'morePostTIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTIKTOKviews: {
        id: 'productPage.morePostTIKTOKviews',
        defaultMessage:
			'morePostTIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostINSTAGRAM: {
        id: 'productPage.morePostINSTAGRAM',
        defaultMessage:
			'morePostINSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostINSTAGRAMfollowers: {
        id: 'productPage.morePostINSTAGRAMfollowers',
        defaultMessage:
			'morePostINSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostINSTAGRAMviews: {
        id: 'productPage.morePostINSTAGRAMviews',
        defaultMessage:
			'morePostINSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostINSTAGRAMlikes: {
        id: 'productPage.morePostINSTAGRAMlikes',
        defaultMessage:
			'morePostINSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostPINTEREST: {
        id: 'productPage.morePostPINTEREST',
        defaultMessage:
			'morePostPINTERESTLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostPINTERESTsaves: {
        id: 'productPage.morePostPINTERESTsaves',
        defaultMessage:
			'morePostPINTERESTLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostPINTERESTfollowers: {
        id: 'productPage.morePostPINTERESTfollowers',
        defaultMessage:
			'morePostPINTERESTLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostSPOTIFY: {
        id: 'productPage.morePostSPOTIFY',
        defaultMessage:
			'morePostSPOTIFYLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTUMBLR: {
        id: 'productPage.morePostTUMBLR',
        defaultMessage:
			'morePostTUMBLRLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTWITCH: {
        id: 'productPage.morePostTWITCH',
        defaultMessage:
			'morePostTWITCHLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostLINKEDIN: {
        id: 'productPage.morePostLINKEDIN',
        defaultMessage:
			'morePostLINKEDINLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostYOUTUBE: {
        id: 'productPage.morePostYOUTUBE',
        defaultMessage:
			'morePostYOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostYOUTUBElikes: {
        id: 'productPage.morePostYOUTUBElikes',
        defaultMessage:
			'morePostYOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostYOUTUBEsubscribers: {
        id: 'productPage.morePostYOUTUBEsubscribers',
        defaultMessage:
			'morePostYOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostYOUTUBEviews: {
        id: 'productPage.morePostYOUTUBEviews',
        defaultMessage:
			'morePostYOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostFACEBOOK: {
        id: 'productPage.morePostFACEBOOK',
        defaultMessage:
			'morePostFACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    'morePostFACEBOOKprofile-followers': {
        id: 'productPage.morePostFACEBOOKprofile-followers',
        defaultMessage:
			'morePostFACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    'morePostFACEBOOKpost-likes': {
        id: 'productPage.morePostFACEBOOKpost-likes',
        defaultMessage:
			'morePostFACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    'morePostFACEBOOKpage-likes': {
        id: 'productPage.morePostFACEBOOKpage-likes',
        defaultMessage:
			'morePostFACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTWITTER: {
        id: 'productPage.morePostTWITTER',
        defaultMessage:
			'morePostTWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTWITTERfollowers: {
        id: 'productPage.morePostTWITTERfollowers',
        defaultMessage:
			'morePostTWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTWITTERretweets: {
        id: 'productPage.morePostTWITTERretweets',
        defaultMessage:
			'morePostTWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    morePostTWITTERlikes: {
        id: 'productPage.morePostTWITTERlikes',
        defaultMessage:
			'morePostTWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    selectService: {
        id: 'productPage.selectService',
        defaultMessage: 'Select service',
    },
    continue: {
        id: 'productPage.continue',
        defaultMessage: 'Continue order',
    },
});

const ProductPage = ({ serverData, name, service }) => {
    const [serviceData, setServiceData] = useState(serverData);
    const [radio, setRadio] = useState(null);

    useEffect(() => {
        if (serverData.length > 0 && name && service) {
            const items = serverData.filter((o) => o.name.replace(' ', '-') === service);
            setServiceData(items);
            setRadio(items[0]?.id);
        }
    }, [serverData, name, service]);

    return (
        <>
            <Seo
                title={messages.metaTitle}
                titleValues={{ product: getServiceName(name), service }}
                description={messages.metaDesc}
                descriptionValues={{ product: getServiceName(name), service }}
            />
            <div className={classNames(styles.productHead, styles[name.toLowerCase()])}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        {name.toLowerCase() === 'tiktok' ? (
                            <img
                                src={tiktokLogo}
                                alt="Tik Tok logo"
                                className={styles.tiktokLogo}
                            />
                        ) : (
                            <i
                                className={`fa fa-${
                                    name.toLowerCase() === 'youtube'
                                        ? 'youtube-play'
                                        : name.toLowerCase()
                                }`}
                            />
                        )}
                        {getServiceName(name)}
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <Post title={messages.title}>
                    {typeof messages[`${name.toUpperCase()}${service}`] !== typeof undefined ? (
                        <FormattedMessage
                            {...messages[`${name.toUpperCase()}${service}`]}
                            values={{
                                br: <br />,
                                h1: (text) => <h1>{text}</h1>,
                                h2: (text) => <h2>{text}</h2>,
                                h3: (text) => <h3>{text}</h3>,
                                h4: (text) => <h4>{text}</h4>,
                                h5: (text) => <h5>{text}</h5>,
                                h6: (text) => <h6>{text}</h6>,
                                b: (text) => <b>{text}</b>,
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            {...messages[`${name.toUpperCase()}`]}
                            values={{
                                br: <br />,
                                h1: (text) => <h1>{text}</h1>,
                                h2: (text) => <h2>{text}</h2>,
                                h3: (text) => <h3>{text}</h3>,
                                h4: (text) => <h4>{text}</h4>,
                                h5: (text) => <h5>{text}</h5>,
                                h6: (text) => <h6>{text}</h6>,
                                b: (text) => <b>{text}</b>,
                            }}
                        />
                    )}
                </Post>
                <h3 className={styles.title}>
                    <FormattedMessage {...messages.selectService} />
                    <div className={styles.grid}>
                        {serviceData.map(
                            ({
                                descriptionTitle,
                                price,
                                recommended,
                                id,
                                name: serviceName,
                                perks,
                            }) => (
                                <label
                                    key={id}
                                    htmlFor={id}
                                    className={classNames(styles.radio, {
                                        [styles.active]: radio === id,
                                    })}
                                    onClick={() => setRadio(id)}
                                >
                                    <div
                                        className={classNames(styles.tick, {
                                            [styles.active]: radio === id,
                                        })}
                                    />
                                    <div className={styles.head}>
                                        <span>
                                            <FormattedMessage
                                                id="productPage.productHead"
                                                defaultMessage="{descriptionTitle} {serviceName} <span>from</span>"
                                                values={{
                                                    descriptionTitle,
                                                    serviceName,
                                                    span: (text) => (
                                                        <span className={styles.thin}>
                                                            {`${text} ${price} $`}
                                                        </span>
                                                    ),
                                                }}
                                            />
                                        </span>
                                        {recommended && (
                                            <div className={styles.recommended}>
                                                <FormattedMessage
                                                    id="productPage.recommended"
                                                    defaultMessage="Recommended"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <ul className={styles.list}>
                                        {Object.values(perks || {}).map((item) => (
                                            <li key={item}>{item}</li>
                                        ))}
                                    </ul>
                                    <input type="radio" id={id} name="option" />
                                </label>
                            ),
                        )}
                    </div>
                </h3>
                <Button
                    type={ButtonType.LINK}
                    link={`/order/${name}/${service}/${radio}`}
                    text={messages.continue}
                />
                <Post title={messages.titleMore}>
                    {typeof messages[`morePost${name.toUpperCase()}${service}`] !== typeof undefined ? (
                        <FormattedMessage
                            {...messages[`morePost${name.toUpperCase()}${service}`]}
                            values={{
                                br: <br />,
                                h1: (text) => <h1>{text}</h1>,
                                h2: (text) => <h2>{text}</h2>,
                                h3: (text) => <h3>{text}</h3>,
                                h4: (text) => <h4>{text}</h4>,
                                h5: (text) => <h5>{text}</h5>,
                                h6: (text) => <h6>{text}</h6>,
                                b: (text) => <b>{text}</b>,
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            {...messages[`morePost${name.toUpperCase()}`]}
                            values={{
                                br: <br />,
                                h1: (text) => <h1>{text}</h1>,
                                h2: (text) => <h2>{text}</h2>,
                                h3: (text) => <h3>{text}</h3>,
                                h4: (text) => <h4>{text}</h4>,
                                h5: (text) => <h5>{text}</h5>,
                                h6: (text) => <h6>{text}</h6>,
                                b: (text) => <b>{text}</b>,
                            }}
                        />
                    )}
                </Post>
            </div>
            <Feedback />
        </>
    );
};

export default ProductPage;
