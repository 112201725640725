// extracted by mini-css-extract-plugin
export var active = "ProductPage-module--active--3feMF";
export var container = "ProductPage-module--container--N678J";
export var facebook = "ProductPage-module--facebook--lKniP";
export var grid = "ProductPage-module--grid--BB3T8";
export var head = "ProductPage-module--head--uXJOC";
export var instagram = "ProductPage-module--instagram--rXXzv";
export var linkedin = "ProductPage-module--linkedin--wL2Lb";
export var list = "ProductPage-module--list--42rmq";
export var logoContainer = "ProductPage-module--logoContainer--0PhGF";
export var pinterest = "ProductPage-module--pinterest--yAdAT";
export var productHead = "ProductPage-module--productHead--eKPHc";
export var radio = "ProductPage-module--radio--Xo4SH";
export var recommended = "ProductPage-module--recommended--WjbPU";
export var spotify = "ProductPage-module--spotify--oVrNQ";
export var thin = "ProductPage-module--thin--uxCfg";
export var tick = "ProductPage-module--tick--58tw3";
export var tiktok = "ProductPage-module--tiktok--R8vou";
export var tiktokLogo = "ProductPage-module--tiktokLogo--dCP88";
export var title = "ProductPage-module--title--OrCmQ";
export var tumblr = "ProductPage-module--tumblr--9R1KV";
export var twitch = "ProductPage-module--twitch--jCz2B";
export var twitter = "ProductPage-module--twitter--yk633";
export var youtube = "ProductPage-module--youtube--Cbgs1";