import { Router } from '@reach/router';
import ProductPage from '../../pages/ProductPage/ProductPage';
import Layout from '../layout';

const ProductRouter = ({ serverData, name, service }) => (
    <Layout>
        <Router>
            <ProductPage
                serverData={serverData}
                name={name}
                service={service}
                path="/buy/:name/:service"
            />
        </Router>
    </Layout>
);

export default ProductRouter;

export async function getServerData({ params }) {
    const { name, service } = params;
    try {
        const res = await fetch(`${process.env.GATSBY_API_URL}/api/service?category=${name}`);
        if (!res.ok) {
            throw new Error('Response failed');
        }
        return {
            props: await res.json(),
            name,
            service,
        };
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {},
        };
    }
}
